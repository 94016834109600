import React from 'react';
import { Header } from '../components/Header';

const NotFoundPage = () => (
  <React.Fragment>
    <Header />
    <div className="wrapper">
      <div className="centralMessage">
        <h1 className="centralMessage__title">NOT FOUND</h1>
        <p className="centralMessage__content">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </div>
  </React.Fragment>
);

export default NotFoundPage;
