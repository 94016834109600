import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../img/ipd-logo.jpg';

import './header.scss';

export const Header = () => (
  <header>
    <div className="header-logo">
      <Link to="/">
        <img src={Logo} alt="logo for IPD" />
      </Link>
    </div>
    <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </nav>
    <div className="header-social">
      <div className="header-social__icons">
        <a
          href="https://www.facebook.com/iprofessdaily"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook" />
        </a>
        <a
          href="https://twitter.com/drvilmavega"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href="https://www.instagram.com/vmvdoc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
      </div>
      <div style={{ flex: 1 }}>
        <a
          href="https://vilmavega.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          by Vilma Vega
        </a>
      </div>
    </div>
  </header>
);
